<template>
  <div style="position: relative;">
    <v-form
      v-if="!showBAA"
      ref="baaForm"
      v-model="baaFormValid"
      :lazy-validation="false"
      @submit.prevent="generateBAA"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <form-input
            v-model="baaData.entityName"
            :rules="rules.entity_name"
            label="Legal name of covered entity"
          />
        </v-col>
        <v-col cols="12">
          <form-input
            v-model="baaData.entityKind"
            :rules="rules.entity_kind"
            label="Kind of entity (e.g., Limited Liability Company)"
          />
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="baaData.entityState"
            :items="states"
            :rules="rules.entity_state"
            hide-no-data
            hide-selected
            rounded
            label="State where covered entity was formed"
            item-text="text"
            item-value="id"
            class="custom-autocomplete-rounded gray"
            :menu-props="{
              offsetY: true,
              contentClass: 'custom-select-menu'
            }"
          />
        </v-col>
        <v-col cols="12">
          <form-input
            v-model="baaData.entityEmail"
            :rules="rules.entity_email"
            label="Email address for communication"
          />
        </v-col>
        <v-col cols="12">
          <form-input
            v-model="baaData.partyName"
            :rules="rules.e_signature"
            label="Name for e-signature"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mb-0"
      >
        <v-col
          cols="12"
          class="mb-0"
        >
          <form-input
            v-model="baaData.partyTitle"
            :rules="rules.position_title"
            label="Position or title within covered entity"
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="pt-10"
      >
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-btn
              x-large
              class="form-button-filled"
              elevation="0"
              type="submit"
              :disabled="!baaFormValid"
            >
              Generate PDF
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <!-- Loading spinner for pending PDF progress -->
    <Loading
      v-if="showBAA"
      :state="pdfLoadProgress < 1"
    />

    <!-- Elements for rendered PDF and controls -->
    <div v-if="showBAA">
      <!-- PDF canvas -->
      <div class="mb-12">
        <pdf
          :src="pdfUrl"
          :page="page"
          @num-pages="pageCount = $event"
          @page-loaded="currentPage = $event"
          @progress="pdfLoadProgress = $event"
        />
      </div>
      <!-- Page, Delete, and Download buttons -->
      <div v-if="pdfLoadProgress == 1">
        <v-col
          cols="12"
          class="mb-12"
        >
          <v-row
            cols="12"
            class="justify-space-around"
          >
            <v-btn
              v-if="currentPage > 1"
              icon
              @click="page -= 1"
            >
              <v-icon>{{ mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              disabled
            >
              <v-icon>{{ mdiChevronLeft }}</v-icon>
            </v-btn>
            {{ currentPage }} / {{ pageCount }}
            <v-btn
              v-if="currentPage < pageCount"
              icon
              @click="page += 1"
            >
              <v-icon>{{ mdiChevronRight }}</v-icon>
            </v-btn>
            <v-btn
              v-else
              icon
              disabled
            >
              <v-icon>{{ mdiChevronRight }}</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row
            cols="12"
            class="col-12 justify-space-around"
          >
            <v-btn
              x-large
              class="form-button-filled baa-form-button"
              elevation="0"
              color="white"
              type="submit"
              min-width="160"
              @click="showBAA = false"
            >
              Delete
            </v-btn>
            <v-btn
              x-large
              class="form-button-filled baa-form-button"
              elevation="0"
              type="submit"
              min-width="160"
              @click="downloadBAA"
            >
              Download
            </v-btn>
          </v-row>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import format from 'string-template'
import pdf from 'vue-pdf'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'

import FormInput from '@/components/form/FormInput'
import Loading from '@/views/dashboard/review/components/Loading'

import states from '@/kit/dictionaries/states'
import emailValidation from '@/helpers/emailValidation'

export default {
  name: 'BAAForm',
  components: {
    FormInput,
    Loading,
    pdf,
  },
  data() {
    return {
      baaData: {
        entityName: '',
        entityKind: '',
        entityCity: '',
        entityState: '',
        entityZipcode: '',
        entityEmail: '',
        partyName: '',
        partyTitle: ''
      },

      // PDF data
      showBAA: false,
      pdfLoadProgress: 0,
      pdfUrl: '',
      currentPage: 0,
      pageCount: 0,
      page: 1,
      baaFormValid: false,

      rules: {
        entity_name: [v => !!v || 'Name of covered entity required'],
        entity_kind: [v => !!v || 'Entity kind required'],
        entity_state: [v => !!v || 'Entity state or region required'],
        entity_email: [
          v => !!v || 'Entity notice email required',
          v => emailValidation(v)
        ],
        e_signature: [v => !!v || 'Name for e-signature required'],
        position_title: [v => !!v || 'Position or title required']
      },

      states: states(),
      mdiChevronLeft,
      mdiChevronRight,
    };
  },
  computed: {
    ...mapGetters('authentication', ['user'])
  },
  methods: {
    ...mapActions('authentication', ['fetchUser', 'logout']),
    validate(ref) {
      this.$refs[ref].validate()
    },
    generateBAA() {
      this.validate('baaForm')

      if (this.baaFormValid) {
        this.pdfUrl = format(
          '{baseUrl}/baa?'+
          'entityName={entityName}&'+
          'entityType={entityKind}&'+
          'state={entityState}&'+
          'entityEmail={entityEmail}&'+
          'partyName={partyName}&'+
          'signatureTitle={partyTitle}', {
            baseUrl: process.env.VUE_APP_API_URL,
            entityName: this.baaData.entityName,
            entityKind: this.baaData.entityKind,
            entityState: this.baaData.entityState,
            entityEmail: this.baaData.entityEmail,
            partyName: this.baaData.partyName,
            partyTitle: this.baaData.partyTitle
        })
        this.showBAA = true
        this.isLoaded = true
      }
    },
    downloadBAA() {
      fetch(this.pdfUrl).then(function(t) {
        return t.blob().then((b) => {
          var a = document.createElement('a')
          a.href = URL.createObjectURL(b)
          a.setAttribute('download', 'AdherelyBAA.pdf')
          a.click()
        })
      })
    },
    deleteBAA() {
      this.showBAA = false
      this.pdfLoadProgress = 0
      this.pdfUrl = ''
      this.currentPage = 0
      this.pageCount = 0
      this.page = 1
    },
    updateProperty(property, value) {
      this.$store.commit('authentication/updateProperty', { property, value })
    },
    async doLogout() {
      await this.logout()
      await this.$router.replace({ name: 'signIn' })
    }
  }
}
</script>

<style lang="scss">
.col {
  padding-top: 0;
  padding-bottom: 0;
}
.form-button-filled {
  color: black !important;
  border-radius: 15px;
  text-transform: none;
  border: none;
  font-weight: bold;

  &.white {
    border: 1px solid #E0E0E0 !important;
  }

  // &.baa-form-button {
  //   min-width: 150px;
  // }
}
p {
  color: #666
}
</style>