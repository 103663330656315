<template>
  <section>
    <page-header sticky>
      Personal settings
      <template slot="interactives">
        <v-btn @click="doLogout">
          Log out
        </v-btn>
        <v-btn
          class="ml-6 cta"
          :disabled="!isChanged || isSaving"
          @click="doSave"
        >
          Save changes
        </v-btn>
      </template>
    </page-header>
    <v-form
      ref="settingsForm"
      v-model="isFormValid"
      style="margin-top: 3rem;"
      @submit.prevent="doSave"
    >
      <v-row
        class="mt-10"
        no-gutters
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3>Personal Info</h3>
          <p>Change your personal information</p>
        </v-col>
        <v-col cols="12">
          <v-row class="mt-0">
            <v-col cols="12">
              <form-input
                v-model="formUser.organization"
                label="Organization"
                autocomplete="organization"
              />
            </v-col>
            <!--  -->
            <v-col cols="2">
              <v-select
                v-model="formUser.title"
                class="custom-text-field"
                filled
                :height="'64'"
                :items="titles"
                label="Title"
              />
            </v-col>
            <v-col cols="5">
              <form-input 
                v-model="formUser.first_name"
                label="First name *"
                :rules="rules.name"
                validate-on-blur
                autocomplete="given-name"
              />
            </v-col>
            <v-col cols="5">
              <form-input
                v-model="formUser.last_name"
                label="Last name *"
                :rules="rules.name"
                validate-on-blur
                autocomplete="family-name"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hr>

      <v-row
        class="mt-12"
        no-gutters
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3>Professional Info</h3>
          <p>Change your professional information</p>
        </v-col>
        <v-col cols="12">
          <v-row class="mt-0">
            <v-col cols="12">
              <v-autocomplete
                v-model="formUser.specialty"
                :items="specialties"
                hide-no-data
                hide-selected
                rounded
                label="Specialty"
                item-text="text"
                item-value="id"
                class="custom-autocomplete-rounded gray"
                :menu-props="{
                  offsetY: true,
                  contentClass: 'custom-select-menu'
                }"
              />
            </v-col>
            <v-col cols="12">
              <form-input
                v-model="formUser.npi"
                label="NPI"
                autocomplete="npi"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                :value="formUser.stateLicensed"
                :items="states"
                hide-no-data
                hide-selected
                rounded
                label="State"
                item-text="text"
                item-value="id"
                class="custom-autocomplete-rounded gray"
                :menu-props="{
                  offsetY: true,
                  contentClass: 'custom-select-menu'
                }"
                @change="e => formUser.stateLicensed = e"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hr v-if="!isPremiumUserWithoutSubscription">

      <v-row
        v-if="!isPremiumUserWithoutSubscription"
        id="subscription"
        class="mt-12"
        no-gutters
      >
        <v-col cols="12">
          <v-row class="mt-0">
            <v-col cols="12">
              <Plans />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <hr>

      <v-row
        class="mt-12 mb-0"
        no-gutters
      >
        <v-col
          cols="12"
          class="pb-4"
        >
          <h3>Password</h3>
          <p>Change your password</p>
        </v-col>
        <v-col cols="12">
          <v-row class="mt-0">
            <v-col cols="12">
              <form-input
                v-model="password.current"
                type="password"
                label="Current password"
                :rules="rules.password"
                validate-on-blur
                autocomplete="current-password"
              />
            </v-col>
            <v-col cols="12">
              <form-input
                v-model="password.new"
                type="password"
                label="New password"
                :rules="rules.newPassword"
                validate-on-blur
                autocomplete="new-password"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
      <!-- Submit button sticks to the bottom until user scrolls past its relative position -->
      <!-- Sticky section not used, reference implementation: -->
      <!-- <sticker position="bottom" class="my-8" sticky-classes="px-12 bg-white">
        <v-row
          class="pt-4 pb-4"
          no-gutters
        >
          <v-col cols="4"/>
          <v-col cols="8">
            <div class="d-flex justify-space-between">
              <div class="d-flex flex-column justify-center">
                <p class="d-flex mb-0">* This field is mandatory</p>
              </div>
              <v-btn
                class="form-button-filled"
                x-large
                type="submit"
                elevation="0"
                :disabled="!isChanged || isSaving"
              >
                Save changes
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </sticker> -->
      <v-row
        class="py-12"
        no-gutters
      >
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column justify-center">
              <p class="d-flex mb-0">
                * This field is mandatory
              </p>
            </div>
            <v-btn
              class="form-button-filled"
              x-large
              type="submit"
              elevation="0"
              :disabled="!isChanged || isSaving"
            >
              Save changes
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <hr class="mt-0">

    <!-- BAA -->
    <v-row
      class="mt-12 mb-0"
      no-gutters
    >
      <v-col
        cols="12"
        class="pb-4"
      >
        <h3>BAA</h3>
        <p>Generate a self-service business agreement</p>
      </v-col>
      <v-col cols="12">
        <baa-form />
      </v-col>
    </v-row>
  </section>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

import BAAForm from '@/components/dashboard/BAAForm'
import PageHeader from '@/components/dashboard/PageHeader'
// import FormAutocomplete from '@/components/form/FormAutocomplete'
import FormInput from '@/components/form/FormInput'
// import Sticker from '@/components/Sticker'
import Plans from '@/components/dashboard/Plans'

import specialties from '@/kit/dictionaries/specialties'
import states from '@/kit/dictionaries/states'
import { join } from 'path'

export default {
  name: 'Settings',
  components: {
    BaaForm: BAAForm,
    PageHeader,
    // FormAutocomplete,
    FormInput,
    // Sticker,
    Plans
  },
  data() {
    return {
      formUser: {},   // User modified in form
      originalUser: {},   // Original user to determine changed fields
      password: {
        current: "",
        new: ""
      },
      isFormValid: false,
      isSaving: false,
      rules: {
        password: [
          // If not set, then it doesn't need to be validated (since we're not updating)
          v => !v || v?.length === 0 || v?.length >= 6 || 'Password should be at least 6 characters'
        ],
        newPassword: [
          // Require 8 char passwords
          // If not set, then it doesn't need to be validated (since we're not updating)
          v => !v || v?.length === 0 || v?.length >= 8 || 'Password should be at least 8 characters'
        ],
        // organization: [v => !!v || 'Organization required'],
        position_title: [v => !!v || 'Position or title required'],
        first_name: [v => !!v || 'First name is required'],
        last_name: [v => !!v || 'Last name is required']
      },
      // Constants
      titles: [
        'Ms.', 
        'Mrs.', 
        'Mr.', 
        'Dr.',
        'Other'
      ],
      specialties: specialties(),
      states: states(),
    };
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('subscription', ['hasActiveSubscription', 'subscription']),
    ...mapGetters('app', ['isLocked']),
    changedFields() {
      const keys = Object.keys(this.formUser)
      const changedFields = {}
      
      keys.forEach(key => {
        // We don't change objects, only primitives
        // console.log(this.formUser[key], typeof this.formUser[key])
        if (typeof this.formUser[key] === 'object') return
        if (this.originalUser[key] === this.formUser[key]) return
        changedFields[key] = this.formUser[key]
      })

      return changedFields
    },
    isChanged() {
      if (
        this.password.current.length > 0 &&
        this.password.new.length > 0
      ) return true

      return Object.keys(this.changedFields).length > 0
    },
    isPremiumUserWithoutSubscription() {
      return this.hasActiveSubscription && !this.subscription
    }
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('authentication', ['fetchUser', 'logout']),
    ...mapActions('app', ['notice', 'unlock']),
    setFullObject(target, props) {
      // Use $set to preserve reactivity since we're adding props to watch in computed
      // https://v2.vuejs.org/v2/guide/reactivity.html#For-Objects
      if (props) {
        const propKeys = Object.keys(props)
        propKeys.forEach(key => {
          this.$set(target, key, props[key])
        })
      }
    },
    init() {
      this.setFullObject(this.formUser, cloneDeep(this.user))
      this.setFullObject(this.originalUser, cloneDeep(this.user))
      this.password.current = '';
      this.password.new = '';
    },
    async refreshUser() {
      await this.fetchUser(this.user.id)
    },
    validate(ref) {
      this.$refs[ref].validate()
    },
    async doLogout() {
      if (this.isLocked) {
        this.unlock()
      }
      if (window.parent) {
        window.parent.postMessage('adherelySessionClosed', '*')
        console.log('window.parent: adherelySessionClosed')
      } else {
        window.postMessage('adherelySessionClosed', '*')
        console.log('window: adherelySessionClosed')
      }
      window.Intercom('shutdown')
      await this.logout()
      await this.$router.replace({ name: 'signIn' })
      // window.location.reload()
    },
    async doChangePassword() {
      const credentials = this.$firebase.auth.EmailAuthProvider.credential(
        this.user.email,
        this.password.current
      )

      // Tests old password
      const userCredential = await this.$firebase
        .auth()
        .currentUser.reauthenticateWithCredential(credentials)
      
      await userCredential.user.updatePassword(this.password.new)
    },
    async doSave() {
      this.validate('settingsForm')
      if (!this.isFormValid) return
      if (!this.isChanged) {
        console.info('No changes to save')
        return
      }
      if (this.isSaving) return
      this.isSaving = true

      const changes = this.changedFields

      console.info('Saving changes:', changes)

      if (this.password.current?.length > 0 && this.password.new?.length > 0) {
        try {
          // These fields can obviously be empty if they're not being changed
          await this.doChangePassword()
        } catch (e) {
          this.notice({
            type: 'err',
            message: e.message ?? 'Could not change password (is the current password correct?)'
          })

          // console.log('Password change failed')

          this.isSaving = false;
          return;
        }
      }

      try {
        await this.$firebase.db
          .collection('users')
          .doc(this.user.id)
          .update(changes)

        // Reload everything
        await this.refreshUser()
        this.init()

        this.isSaving = false;

        await this.notice({
          type: 'info',
          message: 'Changes saved'
        })

      } catch (e) {
        this.error = e
        this.notice({
          type: 'err',
          message: e.message ?? 'Could not save changes (unexpected error occurred)'
        })
      }
    },
    showSuccess() {
      this.showSucces = true
      this.setTimeout(() => this.showSucces = false, 3000)
    },
  }
}
</script>

<style lang="scss">

.col {
  padding-top: 0;
  padding-bottom: 0;
}
.form-button-outlined {
  color: black !important;
  border-radius: 15px;
  text-transform: none;
  background-image: none !important;
  border-color: #e0e0e0 !important;
}
.form-button-filled {
  color: black !important;
  border-radius: 15px;
  text-transform: none;
  border: none;
  font-weight: bold;
}
.bg-white {
  background-color: #fff;
  box-shadow: 0px -10px 20px 0px rgba(#fff, 1.0);
}
// .v-alert.green {
//   // background: linear-gradient(110.13deg, #1FB8AF -2.41%, #12E1BB 97.82%);
//   color: #000 !important;
//   position: fixed;
//   top: 10px;
//   left: 700px;
//   width: auto;
//   z-index: 11;
//   margin: 0px auto;
//   padding: 8px 16px;
//   background: #FFFFFF !important;
//   border: 1px solid #E0E0E0 !important;
//   box-shadow: 0px 3px 3px rgba(114, 129, 167, 0.1) !important;
//   border-radius: 12px !important;
// }
hr {
  margin-top: 50px !important;
  margin-bottom: 40px !important;
  border-top: #e0e0e0 !important;
}
p {
  color: #666
}
</style>