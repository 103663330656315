<template>
  <div>
    <div>
      <v-row
        class=""
      >
        <v-col justify="center">
          <h3>Our Pricing</h3>
          <p>At Adhere.ly we believe cost should never be a barrier. We make our simple, engaging therapy tools affordable for everybody to use.</p>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col style="text-align: center;">
          <v-btn-toggle
            v-model="option"
            class="justify-center"
          >
            <v-btn :value="'Monthly'">
              Monthly
            </v-btn>
            <v-btn :value="'Annually'">
              Annually (save 34%)
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row class="mt-2 mb-2 card-container">
        <v-col class="justify-center">
          <v-card class="pa-4 card">
            <v-list
              id="basic"
              dense
            >
              <v-list-item-title class="list-title">
                <strong>Free</strong> 
                <v-list-item-subtitle>
                  <span>Free forever. Yes, really!</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <sub v-if="subscriptionType('Free')"> *Current plan</sub>
                  <sub v-else>&nbsp;</sub>
                </v-list-item-subtitle>
              </v-list-item-title>
              <hr>
              <v-list-item-title class="price">
                Free
              </v-list-item-title>
              <v-list-item>
                <v-btn
                  v-if="hasActiveSubscription"
                  class="cta"
                  elevation="0"
                  :disabled="freeLoading"
                  @click="manage('free')"
                > 
                  Return to Free
                  <v-progress-circular
                    v-if="freeLoading"
                    indeterminate
                    style="margin-left: 1rem"
                  />
                </v-btn>
                <v-btn
                  v-else
                  class="cta"
                  elevation="0"
                  disabled
                >
                  Free
                </v-btn>
              </v-list-item>
              <v-list-item
                v-for="opt in freeBullets"
                :key="opt"
                class="list-item"
              >
                <v-list-item-icon class="icon">
                  <v-icon color="primary">
                    {{ mdiCheckCircle }}
                  </v-icon>
                </v-list-item-icon>
                {{ opt }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-4 card">
            <v-list
              id="basic"
              dense
            >
              <v-list-item-title class="list-title">
                <strong>Professional</strong> 
                <v-chip
                  variant="elevated"
                  class="primary-background chip"
                >
                  Popular
                </v-chip>
                <v-list-item-subtitle>
                  <span>For individual providers.</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <sub v-if="subscriptionType('Professional')"> *Current plan</sub>
                  <sub v-else>&nbsp;</sub>
                </v-list-item-subtitle>
              </v-list-item-title>
              <hr>
              <v-list-item-title class="price">
                {{ priceMap['Professional'][option] }}
              </v-list-item-title>
              <v-list-item>
                <v-btn
                  v-if="hasActiveSubscription"
                  class="cta"
                  elevation="0"
                  :disabled="proLoading"
                  @click="manage('pro')"
                >
                  Manage subscription
                  <v-progress-circular
                    v-if="proLoading"
                    indeterminate
                    style="margin-left: 1rem"
                  />
                </v-btn>

                <v-btn
                  v-else
                  class="cta"
                  elevation="0"
                  @click="purchase"
                >
                  Upgrade to Pro
                </v-btn>
              </v-list-item>
              <v-list-item
                v-for="opt in proBullets"
                :key="opt"
                class="list-item"
              >
                <v-list-item-icon class="icon">
                  <v-icon color="primary">
                    {{ mdiCheckCircle }}
                  </v-icon>
                </v-list-item-icon>
                {{ opt }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="pa-4 card">
            <v-list
              id="basic"
              dense
            >
              <v-list-item-title class="list-title">
                <strong>Clinic</strong> 
                <v-list-item-subtitle>
                  <span>For clinics.</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <sub v-if="subscriptionType('Clinic')"> *Current plan</sub>
                  <sub v-else>&nbsp;</sub>
                </v-list-item-subtitle>
              </v-list-item-title>
              <hr>
              <v-list-item-title class="price">
                {{ priceMap['Clinic'][option] }}
              </v-list-item-title>
              <v-list-item>
                <v-btn
                  class="cta"
                  elevation="0"
                  @click="openIntercom"
                >
                  Contact us!
                </v-btn>
              </v-list-item>
              <v-list-item
                v-for="opt in clinicBullets"
                :key="opt"
                class="list-item"
              >
                <v-list-item-icon class="icon">
                  <v-icon color="primary">
                    {{ mdiCheckCircle }}
                  </v-icon>
                </v-list-item-icon>
                {{ opt }}
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { mdiCheckCircle } from '@mdi/js'
import { purchase } from 'vue-gtag'

export default {
  name: 'Subscription',
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    return {
      mdiCheckCircle,
      option: 'Monthly',
      freeBullets: [
        'Library of exercises and assessments',
        'Schedule reminders up to one week',
        'Basic reporting and analytics',
        'Basic chat and email support'
      ],
      proBullets: [
        'Library of assessments and exercises',
        'Unlimited client profiles',
        'Edit reminder content',
        'Automate reminders for the year',
        'Advanced reporting and analytics',
        'Export client results',
        'Priority chat and email support'
      ],
      clinicBullets: [
        'Everything in Professional',
        'Advanced custom exercises',
        'Early access to new features',
        'Unlimited individual users',
        'Unlimited individual data',
        'Dedicated Account Manager'
      ],
      freeLoading: false,
      products: {
        Monthly: 'price_1NGO9mCUJ7NdFn24QyWhaFJt',
        Annually: 'price_1NGO9mCUJ7NdFn24m5B7Qoro'
      },
      proLoading: false,
      priceMap: {
        Free: {
          Monthly: 'Free',
          Annually: 'Free'
        },
        Professional: {
          Monthly: '$15/mo',
          Annually: '$120/yr'
        }, 
        Clinic: {
          Monthly: 'Custom',
          Annually: 'Custom'
        }
      },
    }
  },
  computed: {
    ...mapGetters('authentication', ['user']),
    ...mapGetters('app', ['isLocked']),
    ...mapGetters('subscription', ['subscription', 'hasActiveSubscription']),
    customerPortalURL: function() {
      return 'https://billing.stripe.com/p/login/7sIeVf9zb5AbdpK9AA?prefilled_email=' + this.user?.email
    },
    prices: function() {
      return {
        Annually: 'https://buy.stripe.com/cN2cQp5sY70Y4hyeUV?prefilled_email=' + this.user?.email,
        Monthly: 'https://buy.stripe.com/aEU6s108Ebhe6pGdQQ?prefilled_email=' + this.user?.email
      }
    }
  },
  watch: {
    option: function(now, then) {
      if (!now) {
        this.option = then
      }
    }
  },
  mounted() {
    this.setSubscription(this.user?.subscription || null)
  },
  methods: {
    ...mapActions('authentication', ['fetchUser', 'logout']),
    ...mapActions('app', ['unlock']),
    ...mapMutations({setSubscription: 'subscription/setSubscription'}),
    async doLogout() {
      if (this.isLocked) {
        this.unlock()
      }
      if (window.parent) {
        window.parent.postMessage('adherelySessionClosed', '*')
        console.log('window.parent: adherelySessionClosed')
      } else {
        window.postMessage('adherelySessionClosed', '*')
        console.log('window: adherelySessionClosed')
      }
      window.Intercom('shutdown')
      await this.logout()
      await this.$router.replace({ name: 'signIn' })
      // window.location.reload()
    },
    async manage (plan) {
      this[plan + 'Loading'] = true
      const manageAccount = this.$firebase.functions.httpsCallable('stripeManageAccount')
      manageAccount({
        customerId: this.subscription.customer,
        returnUrl: window.location.href
      }).then(res => {
        window.location.href = res.data.url
        this[plan + 'Loading'] = false
      })
    },
    purchase(plan) {
      const url = this.prices[this.option]
      window.location.href = url
      //
      // NOT USED YET: DO NOT DELETE
      // const checkout = this.$firebase.functions.httpsCallable('stripeCheckout')
      // checkout({
      //   allow_promotion_codes: true,
      //   cancelUrl: window.location,
      //   'client-reference-id': this.user.id,
      //   'customer-email': this.user.email,
      //   'line-items': [
      //     {
      //       price: this.products[plan],
      //       quantity: 1
      //     }
      //   ],
      //   mode: 'subscription',
      //   pk: this.publishableKey,
      //   successUrl: window.location,

      // }).then(res => {
      //   console.log('res', res)
      // })
    },
    subscriptionType(type) {
      switch (type) {
        case 'Free':
          return !this.subscription || !this.subscription?.plan?.active || this.subscription.canceled_at
        case 'Professional':
          return this.subscription && !this.subscription.canceled_at && this.subscription.plan.active
        case 'Clinic':
          return false
        default:
          return false
      }
    },
    openIntercom() {
      window.Intercom('showNewMessage', 'I\'d like to learn more about the Clinic plan for Adhere.ly')
    },
  }
}
</script>

<style scoped>

hr {
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
  margin: 1rem auto 1rem;
  max-width: calc(100% - 2rem);
}

.card-container {
  margin: 0 auto;
  max-width: 1140px;
}

.card {
  margin-top: 2rem;
  max-width: 22rem;
  min-height: 32rem;
}

.icon {
  height: 24px;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.cta {
  margin: 1rem auto;
  width: 100%;
}
.options-wrapper {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}

.list {
  border: 1px solid #EAECF0;
  border-radius: 1rem;
  margin: 1rem;
  max-width: 90%;
}

.list-title {
  border-radius: 1rem 1rem 0 0;
  font-size: 1.5rem;
  min-height: 4rem;
  padding: 0 1rem;
  position: relative;
}

.list-item {
  font-size: 0.875rem;
  min-height: 32px !important;
}

.chip {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.icon {
  margin-right: 12px !important;
}

.price {
  font-size: 3rem;
  font-weight: bold;
  margin-left: 1rem;
  text-align: left;
}

@media(min-width: 1265px) {
  .options-wrapper {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
  }

  .list {
    min-width: 271px;
    flex-basis: 19rem;
  }
}

@media(min-width: 1440px) {
  .list-item {
    font-size: 0.875rem;
  }
}

</style>